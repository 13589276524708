.carousel-stripe-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zindex-low;
    height: 100%;
    position: relative;

    &-container:not(.d-none) {
        position: sticky;
        top: 0;
        z-index: $zindex-highest;
        background-color: $stripe-background-default;
        height: $stripe-banner-height;
        transition: $slide-out-animation;
        overflow: hidden;

        & ~ .main-header {
            transition: $slide-out-animation;
        }

        // hide banner if no active items present
        &:not(:has(.fsbloaded)):not(:has(.active)) {
            height: 0;

            & ~ .main-header {
                top: 0;

                .navbar.main-nav .menu-toggleable-left.navbar-toggleable-md {
                    @include media-breakpoint-down(xl) {
                        margin-top: 0;
                        height: calc(100% - $header-height-mobile);
                    }
                }
            }

            & ~ #main-content .filter-sticky-panel {
                @include media-breakpoint-up(md) {
                    top: rem(77);
                }

                @include media-breakpoint-only(md) {
                    top: $header-height-mobile;
                }
            }
        }
    }

    &-container.d-none ~ .main-header .navbar.main-nav .menu-toggleable-left.navbar-toggleable-md {
        margin-top: 0;
        height: calc(100% - $header-height-mobile);
    }

    .rendering-template.carousel-item:not(.active):not(.carousel-item-next) {
        display: none;
    }

    .carousel-inner {
        width: auto;
        flex: 1;
        text-align: center;
    }

    .carousel-item {
        padding: rem(12);
        line-height: rem(20);

        a {
            color: $stripe-text-color;
            font-size: $font-size-14;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            letter-spacing: rem(0.96);
            line-height: rem(19.2);
        }
    }
}
